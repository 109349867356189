<template>
    <main>
        <div class="d-flex justify-content-end">
        <button class="btn btn-light btn-sm text-primary justify-content-end mb-2" @click="openModalHistoyResults()"><i class="fa fa-eye fa-sw"></i>Ver Historial de resultados</button>
      </div>
      <modalHistoryResults :idUsuario="idUsuario" ref="modalResults"></modalHistoryResults>
        <div class="mt-2">
            <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="pills-tri01-tab" data-toggle="pill" href="#pills-tri01" role="tab"
                        aria-controls="pills-tri01" aria-selected="true">I TRIMESTRE</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="pills-tri02-tab" data-toggle="pill" href="#pills-tri02" role="tab"
                        aria-controls="pills-tri02" aria-selected="false">II TRIMESTRE</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="pills-tri03-tab" data-toggle="pill" href="#pills-tri03" role="tab"
                        aria-controls="pills-tri03" aria-selected="false">III TRIMESTRE</a>
                </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-tri01" role="tabpanel" aria-labelledby="pills-tri01-tab">
                    <indexExamenes ref="examenes_primer_trimestre" :idHistoria="idHistoria" :finalizada="finalizada"
                        :idUsuario="idUsuario" :id-tipo-historia="idTipoHistoria" :trimestre="1"></indexExamenes>
                    <button class="btn btn-success btn-sm" type="button"  @click="savePrimerTrimestre();"><i
                            class="fa fa-save fa-fw"></i>Guardar</button>
                </div>
                <div class="tab-pane fade" id="pills-tri02" role="tabpanel" aria-labelledby="pills-tri02-tab">
                    <indexExamenes ref="examenes_segundo_trimestre" :idHistoria="idHistoria" :finalizada="finalizada"
                        :idUsuario="idUsuario" :id-tipo-historia="idTipoHistoria" :trimestre="2"></indexExamenes>
                    <button class="btn btn-success btn-sm" type="button" @click="saveSegundoTrimestre();"><i
                            class="fa fa-save fa-fw"></i>Guardar</button>
                </div>
                <div class="tab-pane fade" id="pills-tri03" role="tabpanel" aria-labelledby="pills-tri03-tab">
                    <indexExamenes ref="examenes_tercer_trimestre" :idHistoria="idHistoria" :finalizada="finalizada"
                        :idUsuario="idUsuario" :id-tipo-historia="idTipoHistoria" :trimestre="3"></indexExamenes>
                    <button class="btn btn-success btn-sm" type="button"  @click="saveTercerTrimestre();"><i
                            class="fa fa-save fa-fw"></i>Guardar</button>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import indexExamenes from "../base/baseReporteExamenes/index/indexExamenes";
import modalHistoryResults from "../base/baseReporteExamenes/index/modalHistoryResults.vue";
export default {
    props: ['idHistoria', 'finalizada', 'idUsuario', 'idTipoHistoria'],
    components: { indexExamenes,modalHistoryResults },
    methods: {
        savePrimerTrimestre() {
            this.$refs.examenes_primer_trimestre.save();
        },
        saveSegundoTrimestre() {
            this.$refs.examenes_segundo_trimestre.save();
        },
        saveTercerTrimestre() {
            this.$refs.examenes_tercer_trimestre.save();
        },
        openModalHistoyResults(){
            this.$refs.modalResults.openModal();
        },
        
    },
   
}
</script>
  
  