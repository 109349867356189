<template>
    <main ref="resumen" id="resumen">
        <div class="card mt-2 shadow-none" v-if="isLoading">
            <div class="card-body">
                <content-placeholders :rounded="true">
                    <content-placeholders-heading :img="false" />
                    <content-placeholders-text :lines="3" />
                </content-placeholders>
            </div>
        </div>
        <div v-else>
            <div class="py-2">
                <motivoConsultaShow :idHistoria="idHistoria" :data="resumen.motivo_consulta"></motivoConsultaShow>
            </div>
            <div class="py-2">
                <examenFisicoShow :idHistoria="idHistoria" :examenFisico="resumen.examen_fisico"></examenFisicoShow>
            </div>
            <div class="py-2">
                <examenFisicoEmbarazoShow :idHistoria="idHistoria"></examenFisicoEmbarazoShow>
            </div>

            <div class="py-2">
                <exploracionFisicaShow :idHistoria="idHistoria" :data="resumen.exploracion_fisica">
                </exploracionFisicaShow>
            </div>
            <div class="py-2">
                <riesgoBiopsicosocialShow :idHistoria="idHistoria"></riesgoBiopsicosocialShow>
            </div>
            <div class="py-2">
                <showExpViolencia :idHistoria="idHistoria" :codigo="'EXP_VIOL'"></showExpViolencia>
            </div>
            <div class="py-2">
                <showRiesgoDepresion :idHistoria="idHistoria" :codigo="'R_DEP'"></showRiesgoDepresion>
            </div>

            <div class="py-2" v-if="resumen && resumen.impresion_diagnostica">
                <impresionDxShow :idHistoria="idHistoria" :data="resumen.impresion_diagnostica"></impresionDxShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.ordenes">
                <ordenClinicaShow :idHistoria="idHistoria" :data="resumen.ordenes"></ordenClinicaShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.formulas">
                <formulaMedicaShow :idHistoria="idHistoria" :data="resumen.formulas"></formulaMedicaShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.usuario">
                <vacunasShow :idHistoria="idHistoria" :data="resumen.usuario"></vacunasShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.reporte_examenes_embarazo
            ">
                <examenesShow :idHistoria="idHistoria" :idUsuario="idUsuario" :data="resumen.reporte_examenes_embarazo
            "></examenesShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.embarazo_actual">
                <embarazoActualShow :idHistoria="idHistoria" :idUsuario="idUsuario" :data="resumen.embarazo_actual">
                </embarazoActualShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.intervenciones">
                <intervencionesShow :idHistoria="idHistoria" :data="resumen.intervenciones"></intervencionesShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.recomendaciones">
                <recomendacionesShow :idHistoria="idHistoria" :data="resumen.recomendaciones"></recomendacionesShow>
            </div>
        </div>
    </main>
</template>

<script>
import motivoConsultaShow from "../../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../../base/examenFisico/examenFisicoShow";
import impresionDxShow from "../../base/impresionDX/impresionDxShow";
import exploracionFisicaShow from "../../base/examenFisico/exploracionFisicaShow";
import historiaClinicaService from "../../../../services/historiaClinicaService";
import intervencionesShow from "../../base/intervenciones/intervencionesShow";
import recomendacionesShow from "../../base/recomendaciones/recomendacionesShow";
import ordenClinicaShow from "../../base/orden/ordenClinicaShow";
import formulaMedicaShow from "../../base/formulaMedica/formulaMedicaShow";
import riesgoBiopsicosocialShow from "../../base/baseRiesgobiopsicosocial/index/riesgoBiopsicosocialShow";
import vacunasShow from "../../base/baseVacunas/index/vacunasShow";
import examenesShow from "../../base/baseReporteExamenes/index/examenesShow";
import embarazoActualShow from "../../base/baseEmbarazoActual/embarazoActualShow";
import examenFisicoEmbarazoShow from "../../base/examenFisico/examenFisicoEmbarazoShow";
import showExpViolencia from "../../base/baseRespuestaFormularios/showRespuestas";
import showRiesgoDepresion from "../../base/baseRespuestaFormularios/showRespuestas";
export default {
    props: ["idHistoria", "idUsuario"],
    components: {
        motivoConsultaShow,
        examenFisicoShow,
        intervencionesShow,
        recomendacionesShow,
        impresionDxShow,
        exploracionFisicaShow,
        riesgoBiopsicosocialShow,
        examenesShow,
        embarazoActualShow,
        ordenClinicaShow,
        formulaMedicaShow,
        examenFisicoEmbarazoShow,
        showExpViolencia,
        showRiesgoDepresion,
        vacunasShow
    },
    data() {
        return {
            resumen: {},
            isLoading: true,
        };
    },

    async created() {
        this.isLoading = true;
        const response = await historiaClinicaService.resumenHc(this.idHistoria);
        this.resumen = response.data;
        this.isLoading = false;
    },
};
</script>

<style scoped>
.resumen-container {
    height: 300px;
    overflow-y: scroll;
}
</style>
