<template>
    <div class=" ">
        <div class="card-header bg-light "><span><i class="fa fa-file-medical"> </i> Embarazo Actual</span> </div>
        <div class="card-body">
            <div class="row">
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Ultima Menstruación: {{data.ultima_menstruacion}}</label>
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Semanas de gestación: {{data.semanas_gestacion}}</label>
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Fecha probable de parto: {{data.fecha_parto}}</label> 
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Presencia de movimientos fetales: {{data.movimientos_fetales}}</label> 
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Sintomas urinarios: {{data.sintomas_urinarios}}</label> 
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Sintomas cervico vaginales: {{data.cervico_vaginales}}</label> 
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Cefalea persistente: {{data.cefalea_persistente}}</label> 
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Edema en cara o miembros: {{data.edema}}</label> 
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Epigastralgia: {{data.epigastralgia}}</label> 
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Nauseas y vomito: {{data.nauseas}}</label> 
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['idHistoria','idUsuario','data'],
}
</script>